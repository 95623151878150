
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import ComponentItemFormMixin from "./item-form.mixin";

@Component({
  components: {},
  mixins: [],
})
export default class SelectFormBuilder extends Mixins(ComponentItemFormMixin) {
  render() {
    const { conf, value } = this;
    return (
      <q-select
        dense
        filled
        label={conf.label}
        options={conf.selectOptions}
        multiple={conf.multipleSelect}
        value={value}
        emit-value
        map-options
        onInput={(val: any) => this.$emit("change", val)}
        options-dense
        menu-offset={[0, 5]}
      />
    );
  }
}
